html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentFooter {
  flex: 1;
  padding-bottom: auto;
  /* Otros estilos para tu contenido, como padding o margin */
}

.personal-body {
  max-width: 75%;
  margin: 0 auto
}

img {
  max-width: 1440px;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slider-home-style {
  padding-top: 50px;
}

div .center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.footer {
  color: #FFFFFF;
  background-color: #000000;
  margin-top: 50px;
  height: 125px;
}

.footer-logo {
  max-width: 200px;
  display: flex; 
  margin-left: auto; 
  margin-right: auto;
  margin-top: 20px;
}

.social-footer {
  font-size: 30px;
  padding-top: 5px;
}

.info-footer {
  text-align: right;
  padding-right: 10px;
}

.social-footer-mobile {
  font-size: 20px;
  padding-top: 3px;
}

.info-footer-mobile {
  text-align: right;
  padding-right: 7px;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

.imagen-responsive-center {
  width: 90%; 
  height: auto;
  display: flex; 
  margin-left: auto; 
  margin-right: auto;
  margin-top: 50px;
}

.imagen-responsive-center-roudend { 
  border-radius: 9999px;
}

body {
  width: 100%;
  margin: 8px auto 8px auto;
  position: relative;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  margin: auto;
  width: 90%;
  padding: 5px;
}

.column-left {
  float: left;
  width: 35%;
  text-align: center;
  margin-left: 15%;
}

.column-right {
  float: right;
  width: 35%;
  text-align: center;
  margin-right: 15%;
}

.column-img-left {
  float: left;
  width: 44%;
  margin-left: 5%;
  margin-right: 1%;
}

.column-img-right {
  float: right;
  width: 44%;
  margin-right: 5%;
  margin-left: 1%;
}

.img-left-pack {
  float: left;
  width: 18%;
  margin-left: 21%;
  margin-right: 4%;
}

.img-right-pack {
  float: right;
  width: 18%;
  margin-right: 21%;
  margin-left: 4%;
}

.column-pack-right {
  float: right;
  width: 44%;
  margin-right: 10%;
  margin-left: 1%;
  margin-top: 1%;
}

.column-pack-left {
  float: left;
  width: 44%;
  margin-left: 10%;
  margin-right: 1%;
  margin-top: 1%;
}

.cuadriculas-imagenes {
  height: auto;
}

.column-img-trio-left {
  float: left;
  width: 32%;
  margin-left: 1%;
}

.column-trio-left-footer {
  float: left;
  width: 32%;
  margin-left: 1%;
}

.column-img-duo-left {
  float: left;
  width: 45%;
  margin-left: 1%;
}

.column-img-trio-left-radius {
  float: left;
  width: 32%;
  margin-left: 1%;
  border-radius: 9999px;
}

.column-img-trio-right {
  float: right;
  width: 23%;
  margin-left: 1%;
}

.column-img-trio-center {
  float: center;
  width: 23%;
  margin-left: 5%;
  margin-right: 1%;
}

.description-align {
  text-align: center;
  width: 90%; 
  height: auto;
  display: float; 
  margin-left: auto; 
  margin-right: auto;
  margin-top: 50px;
}

section {
  display: flex;
  width: 90%;
  height: 500px;
}

section img {
  width: 0px;
  flex-grow: 1;
  object-fit: cover;
  opacity: .8;
  transition: .5s ease;
}

section img:hover {
  width: 720px;
  opacity: 1;
  filter: contrast(120%);
}

.alinear-section-home {
  display: flex;
  justify-content: center;
}

.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0.5); /* Black see-through */
  color: #ffffff; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.text-movil {
  max-width: 80%;
}


/* FOOTER */
footer {
  background-color: #000000;
  padding: 20px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.column img {
  max-width: 50%;
  height: auto;
}

.footer-content h2 {
  margin-bottom: 10px;
  color: #9D9D9D;
  font-size: 25px;
}

.footer-content p {
  margin: 5px 0;
  color: #FFFFFF;
}

.social-links {
  list-style: none;
  padding: 0;
}

.social-links li {
  display: inline;
  margin: 0 5px;
}

.social-links a {
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
}

.footer-content form {
  margin-top: 10px;
}

.footer-content button {
  margin: 10px;
  border-radius: 12px;
}

.footer-content input {
  border-radius: 12px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-options {
  display: none;
  position: absolute;
  overflow: auto;
}

.dropdown:hover .dropdown-options {
  display: block;
}

.dropdown button {
  border: none;
  background-color: #FFFFFF;
}

.dropdown-options div {
  padding-left: 15px;
}

.title {
  text-align: center;
}

.container-text {
  max-width: 800px;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

.alinear-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}